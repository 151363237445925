
















































































// Vue
import Vue from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
// API
import { InitiateResetPasswordInfo, UserClient } from "@/core/api/PharmacyReimbursementApi";

// API Clients
const userClient = new UserClient();

export default Vue.extend({
  name: "ForgotPassword",
  mixins: [validationMixin],

  data() {
    return {
      isLoading: false,
      successResetLinkSent: false,
      errorResetLinkSent: false,

      email: undefined as string | undefined
    };
  },

  validations: {
    email: { required, email }
  },

  computed: {
    emailErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Please enter a valid email");
      return errors;
    }
  },

  methods: {
    async sendResetLink(): Promise<void> {
      if (!this.email) return;

      const payload = {
        email: this.email
      } as InitiateResetPasswordInfo;

      try {
        this.isLoading = true;
        this.successResetLinkSent = false;
        this.errorResetLinkSent = false;

        await userClient.initiateResetPassword(payload);
        this.successResetLinkSent = true;
      } catch (error) {
        console.error(error);
        this.successResetLinkSent = true;
      } finally {
        this.isLoading = false;
      }
    },

    clearForm(): void {
      this.email = "";
      this.$v.$reset();
    }
  }
});
